import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './onboarding-screen.scss';
import Field from '../Field/Field';
import { MAIN_CONFIG } from '../../../config/main';
import CTAButton from '../CTAButton/CTAButton';
import KaleidoscopeAPI from '../../Core/KaleidoscopeAPI';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FlashMessage from '../FlashMessage/Message';
import Loader from '../Loader/Loader';
import { useRouter } from 'next/router';
import Success from '../SVG/Success';
import Warning from '../SVG/Warning';
import LoggedOutHeader from '../../Layout/AppHeader/LoggedOutHeader/LoggedOutHeader';
import ParentConsentModal from '../Application/ParentConsentModal/ParentConsentModal';
import { blankSpaceCheck, checkFieldLength, handleKeyDown } from '../../Utility/ApplicationFormUtility';
import SweetAlert from 'react-bootstrap-sweetalert';
import Alert from '../SVG/Alert';
import Modal from '../Modal/Modal';
import CTAAnchorTag from '../CTAAnchorTag/CTAAnchorTag';
import { handleRedirectHrefUrl } from '../../Utility/HelperFunctions';


const API = new KaleidoscopeAPI({});

const OnboardingScreen = ({ pageType = 'signup', setModalState, emailDisabled = false }) => {
    const router = useRouter();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { USER_ROLE_CONTEXT } = MAIN_CONFIG;
    const [isLoading, setIsLoading] = useState(false)
    const [isShowSightIn, setIsShowSightIn] = useState(false)
    const [token, setToken] = useState(false)
    const [state, setState] = useState({
        password: '',
        errors: '',
        hasSymbol: false,
        hasUpperCase: false,
        hasLength: false,
        hasLowerCase: false,
        hasNumber: false,
    });
    const [emailValidateError, setEmailValidateError] = useState("")

    const [thirteenYearAge, setThirteenYearAge] = useState(false);
    const [cellPhone, setCellPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [smsNotification, setsmsNotification] = useState(true)
    const [emailNotification, setEmailNotification] = useState(true)
    const [promotionalNotification, setPromotionalNotification] = useState(true)
    const [updateAlert, setUpdateAlert] = useState(false);
    let redirectApply = sessionStorage.getItem("redirectApply")
    let userEmail = sessionStorage.getItem("userEmail")
    const [showParentConsentModal, setShowParentConsentModal] = useState(false);

    const toggleParentConsentModal = () => {
        setShowParentConsentModal(!showParentConsentModal);
    };

    useEffect(() => {
        if (userEmail) {
            setEmail(userEmail);
        }
    }, []);

    const goToNextStep = (e) => {
        e.preventDefault()
            if (firstName && lastName && email && password && emailValidateError == "") {
                if (thirteenYearAge) {
                    if (blankSpaceCheck(firstName, lastName)) {
                        toast.error(<FlashMessage type={'error'} message="You can't add spaces at the beginning of the first name or last name." />)
                    }else if (checkFieldLength(firstName, 40)) {
                        toast.error(<FlashMessage type={'error'} message="First name must be less than 40 characters." />);
                    } else if (checkFieldLength(lastName, 80)) {
                        toast.error(<FlashMessage type={'error'} message="Last name must be less than 80 characters." />);
                    }else if (checkFieldLength(email, 80)) {
                        toast.error(<FlashMessage type={'error'} message="Email must be less than 80 characters." />);
                    }else if(!cellPhone) {
                        setPhoneError('Mobile phone is required');
                        toast.error(<FlashMessage type={'error'} message="Mobile phone is required." />);
                        return;
                      }else {
                        handleSubmitFirstForm()
                    }
                } else {
                    setShowParentConsentModal(true);
                    setIsLoading(false);
                    return false
                }
        }
    };

    const handleParentConsent = async (applicantName, guardianEmail) => {
        const response = await API.parentalConsentRequest({ applicant_name: applicantName, guardian_email: guardianEmail, scholarshipId: '' });
        if (response.success) {
            toast.success(<FlashMessage message={response.message} />);
            setTimeout(() => {
                router.push("/", undefined, { shallow: true })
            }, 5000);
        } else {
            toast.error(<FlashMessage type={'error'} message={response.message} />);
            setIsLoading(false)
            return false
        }
    }

    const redirectPath = () => {
        if(localStorage.redirectURL) {
            return localStorage.redirectURL
        } else if (sessionStorage.previousPath) {
            return sessionStorage.previousPath
        } else {
        return MAIN_CONFIG.URLS.APPLICANT.APPLICATIONS
        }
    } 
    const handleSubmitFirstForm = async () => {
        setIsLoading(true)
        let user = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            cell_phone: cellPhone,
            smagicinteract__smsoptout__c: smsNotification,
            hasoptedoutofemail: emailNotification,
            promotional_email_opt_out__c: promotionalNotification
        }
        const auth = await API.authenticateSignUpOnboarding(user);
        if (auth.user) {
            const roles = auth.user.role;
            let userRole = ''
            let availableRoleContexts = USER_ROLE_CONTEXT.map(i => i.role);
            roles.forEach(role => {
                if (availableRoleContexts.includes(role)) {
                    userRole = role;
                }
            });
            const roleContext = USER_ROLE_CONTEXT.find(i => i.role === userRole);
            document.cookie = `token=${auth.user.token};path=/`;
            document.cookie = `context=${roleContext ? roleContext.context : ''};path=/`;
            setToken(auth.user.token)
            window.scrollTo(0, 0)
            toast.success(<FlashMessage message={' Registered successfully.'} />);

            
            const url = redirectPath()
            handleRedirectHrefUrl(url)
        } else {
            // TODO: Handle error here
            toast.error(<FlashMessage type={'error'} message={auth.message} />);
            setIsLoading(false)
        }
    }

    const checkPasswordStrenght = (value) => {
        const hasNumber = /\d/.test(value);
        const hasLength = value.length >= 10
        const hasLowerCase = /[a-z]/.test(value);
        const hasUpperCase = (/[A-Z]/.test(value));
        const hasSymbol = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);
        setState(prevState => {
            return {
                ...prevState,
                hasNumber: hasNumber,
                hasLength: hasLength,
                hasUpperCase: hasUpperCase,
                hasLowerCase: hasLowerCase,
                hasSymbol: hasSymbol
            }
        })

    }

    const handleChangePassword = (value) => {
        checkPasswordStrenght(value || "")
        setPassword(value)
    }

    const handleKeyEnter = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setThirteenYearAge(!event.target.checked)
        }
    }

    const handleClose = () => {
        setEmailNotification(true)
        setUpdateAlert(false)
    }

    const redirectRoute = (e) => {
        e.preventDefault()
        router.push(`${MAIN_CONFIG.URLS.LOGIN}`, undefined, { shallow: true })
    }

    const getConfirmation = (e) => {
        const alert = () => (
          <div className="save-changes-confirmation-alert modal-utility confirmation_modal">
            <SweetAlert
              showConfirm={true}
              confirmBtnText="Okay"
              showCancel={true}
              cancelBtnText="Cancel"
              customButtons={
                <div className='action-footer'>
                  <button onClick={handleClose} className='cta-button CTABlack cta-button__text'>Cancel</button>
                  <button onClick={(e) => setUpdateAlert(false)}
                    className='cta-button CTAWhite tertiary cta-button__text'>Opt-out</button>
                </div>
              }
              className=""
            >
              <div style={{ display: 'flex' }}>
                <div>
                  <Alert />
                </div>
                <div className='content'>
                  <p className='title'>Are you sure you want to opt-out of emails?</p>
                  <p className="CTAGrey">By opting out of email notifications, you will not receive
                    any emails from Kaleidoscope. This includes notifications
                    about your application status and award information.
                  </p>
                </div>
              </div>
            </SweetAlert>
          </div>
        );
        setUpdateAlert(alert)
      }

    return (
        <div id={!emailDisabled ? 'onboarding-screen' : 'task-modal-content'} style={{ marginTop: "-80px" }}>
            {!emailDisabled && <div id='header' className='logged-out'>
                <div id="header__inner" className="header LOGGED_OUT">
                    <LoggedOutHeader pageType={pageType} isShowSightIn={isShowSightIn} />
                </div>
            </div>}
            <Loader loading={isLoading}/>
            <div id='onboarding-screen__content'>
                    {updateAlert}
                        <div id='onboarding-screen__form-container'>
                            <h2 id='onboarding-screen__form-title' className='H1DesktopGreen'>Let's create your account.</h2>
                            {redirectApply && <div id='onboarding-screen__form-sub-title' className='BodyDefaultBoldBlack'>By creating a Kaleidoscope account, your application progress will save as you go! Login anytime to resume or track application progress.</div>}
                        <form onSubmit={goToNextStep}>
                            <Field
                                className='onboarding-screen__field'
                                label='Email Address'
                                type='email'
                                value={email}
                                setEmailValidateError={(val) => setEmailValidateError(val)}
                                handleChange={setEmail}
                                disabled={true}
                                required
                            />

                            <Field
                                className='onboarding-screen__field'
                                label='First Name'
                                value={firstName}
                                handleChange={setFirstName}
                                required
                                tabindex={0}
                            />

                            <Field
                                className='onboarding-screen__field'
                                label='Last Name'
                                value={lastName}
                                handleChange={setLastName}
                                required
                            />

                            <Field
                                className='onboarding-screen__field'
                                type='phone'
                                label='Mobile Phone'
                                value={cellPhone}
                                handleChange={setCellPhone}
                                required={true}
                                errorMsg={phoneError}
                            />

                            <Field
                                className='onboarding-screen__field'
                                label='Create a Password'
                                type='password'
                                value={password}
                                handleChange={handleChangePassword}
                                required
                            />

                            {
                                password &&
                                <div className="form-group">
                                    <div className="password-policy-list">
                                        <ul className="list-items">
                                            <li className="BodyDefault">{state.hasSymbol ? <Success color={MAIN_CONFIG.COLORS.fillGreen} iconColor={MAIN_CONFIG.COLORS.fillWhite} /> : <Warning />} MUST contain at least one special character</li>
                                            <li className='BodyDefault special-charactor'>( ! " # $ % ^ & * ( ) _ - + = ` ~ \ /)</li>
                                            <li className="BodyDefault">{state.hasUpperCase ? <Success color={MAIN_CONFIG.COLORS.fillGreen} iconColor={MAIN_CONFIG.COLORS.fillWhite} /> : <Warning />} MUST contain at least one uppercase letter</li>
                                            <li className="BodyDefault">{state.hasLowerCase ? <Success color={MAIN_CONFIG.COLORS.fillGreen} iconColor={MAIN_CONFIG.COLORS.fillWhite} /> : <Warning />} MUST contain at least one lowercase letter</li>
                                            <li className="BodyDefault">{state.hasNumber ? <Success color={MAIN_CONFIG.COLORS.fillGreen} iconColor={MAIN_CONFIG.COLORS.fillWhite} /> : <Warning />} MUST contain at least one number</li>
                                            <li className="BodyDefault">{state.hasLength ? <Success color={MAIN_CONFIG.COLORS.fillGreen} iconColor={MAIN_CONFIG.COLORS.fillWhite} /> : <Warning />} MUST contain at least 10 characters (12+ recommended)</li>
                                        </ul>
                                    </div>
                                </div>
                            }

                            <div className="onboarding-screen-i-confirm-and-i-agree">
                                <div className="form-group">
                                    <input
                                        className="application-section__textarea"
                                        type="checkbox"
                                        onChange={(e) => setThirteenYearAge(e.target.checked)}
                                        checked={thirteenYearAge}
                                        onKeyDown={(e) => handleKeyEnter(e)}
                                        aria-label='I confirm that I am at least 13 years old'
                                    />
                                    <span className='BodyDefaultBoldBlack'>
                                        <div className='i-confirm-that-i-am'>I confirm that I am at least 13 years old</div>
                                    </span>
                                </div>
                            </div>

                            <div className="onboarding-screen-i-confirm-and-i-agree">
                                <div className="form-group">
                                    <input
                                        className="application-section__textarea"
                                        type="checkbox"
                                        onChange={(e) => setsmsNotification(e.target.checked)}
                                        checked={smsNotification}
                                        aria-label='Opt-in to program related SMS notifications'
                                    />
                                    <span className='BodyDefaultBoldBlack'>
                                        <div className='i-confirm-that-i-am'>Opt-in to program related SMS notifications</div>
                                    </span>
                                </div>
                            </div>

                            <div className="onboarding-screen-i-confirm-and-i-agree">
                                <div className="form-group">
                                    <input
                                        className="application-section__textarea"
                                        type="checkbox"
                                        onChange={(e) => {
                                            setEmailNotification(e.target.checked);
                                            if (!e.target.checked) {
                                                getConfirmation();
                                            }
                                        }}
                                        checked={emailNotification}
                                        aria-label='Opt-in to email notifications about your application status'
                                    />
                                    <span className='BodyDefaultBoldBlack'>
                                        <div className='i-confirm-that-i-am'>Opt-in to email notifications about your application status</div>
                                    </span>
                                </div>
                            </div>

                            <div className="onboarding-screen-i-confirm-and-i-agree">
                                <div className="form-group">
                                    <input
                                        className="application-section__textarea"
                                        type="checkbox"
                                        onChange={(e) => setPromotionalNotification(e.target.checked)}
                                        checked={promotionalNotification}
                                        aria-label="Opt-in to promotional emails"
                                    />
                                    <span className='BodyDefaultBoldBlack'>
                                        <div className='i-confirm-that-i-am'>Opt-in to promotional emails</div>
                                    </span>
                                </div>
                            </div>
                            <div className='signup-page__btn-container'>
                            <CTAAnchorTag className='CTA-Anchor-Tag-css' ariaLabel="Back button"  handleClick={redirectRoute} showArrow={true}>Back</CTAAnchorTag>
                            <CTAButton id='onboarding-screen__form-submit' type='tertiary' arrow={true}  >
                                Submit
                            </CTAButton>
                            </div>
                        </form>
                        </div>
            </div>
            {showParentConsentModal && (
                <div className="modal-overlay">
                <Modal >
                <ParentConsentModal
                    handleClose={toggleParentConsentModal}
                    handleParentConsent={handleParentConsent}
                    setLoading={setIsLoading}
                    applicantEmail={email}
                    setModalState={setModalState}
                />
                </Modal>
                </div>
            )}

            <ToastContainer />
        </div>
    )
}

OnboardingScreen.propTypes = {
    pageType: PropTypes.string,
    setModalState: PropTypes.func,
    emailDisabled: PropTypes.bool
  };
  
export default OnboardingScreen;
  