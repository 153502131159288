import React, { useState, useEffect } from 'react';
import Field from '../../Field/Field';
import SpaceInvader from '../../SVG/SpaceInvader'
import CTAButton from '../../CTAButton/CTAButton';
import SweetAlert from 'react-bootstrap-sweetalert';
import { checkMarketPlace } from '../../../Utility/HelperFunctions';
// import "./EnterPasswordModal.scss";
import "./ParentConsentModal.scss"
import { handleKeyDown } from '../../../Utility/ApplicationFormUtility';
import Close from '../../SVG/Close';
import { MAIN_CONFIG } from '../../../../config/main';


export default ({
  handleClose = () => { },
  setLoading = () => { },
  handleParentConsent = () => { },
  applicantEmail
}) => {

  const [applicantName, setApplicantName] = useState('');
  const [guardianEmail, setGuardianEmail] = useState('');
  const [btnDisabled, setbtnDisabled] = useState(false);

  const onClose = (e) => {
    handleClose()
    // window.location.href = "/"
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (applicantEmail !== guardianEmail) {
      setLoading(true)
      setbtnDisabled(true)
      handleParentConsent(applicantName, guardianEmail)
    }
  }


  return (
    <div id='task-modal-content' className='parental-consent-required-modal-popup'>
      <div className="enter-password-modal">
        <div className="task-head_container">
          <div className="H1DesktopGreen">Parental Consent Required</div>
          <button onClick={onClose} onKeyDown={(e)=>handleKeyDown(e,()=>onClose(e))} className="event-head H6DesktopGrey"><Close color={MAIN_CONFIG.COLORS.fillGrey} ariaLabel='Close' ariaLabelDescription='' /></button>
        </div>
        <div className='CTAGrey short-description'>
          <p>Because you do not meet the minimum age requirement for your country, you must receive parental consent to complete an application and create an account with Kaleidoscope.</p>
          <p>Please enter a parent email address below. Your parent will be asked to complete a parent consent form. Once complete, you will receive a confirmation email and can begin the application process.</p>
        </div>

        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="enter-password-modal__field-container">
            <Field
              id='login-page__email'
              label='Your Full Name'
              placeholder='Your Full Name'
              type='text'
              value={applicantName}
              handleChange={setApplicantName}
              required
            />
            <Field
              id='login-page__email'
              label='Parent Email Address'
              placeholder='Parent Email Address'
              type='email'
              value={guardianEmail}
              handleChange={setGuardianEmail}
              required
              errorMsg={(applicantEmail == guardianEmail) ? "Email address must be different than the email on your account." : ""}
            />
          </div>

          <div className="enter-password-modal__button-container">
            <CTAButton
              id='task-page__cta'
              type='tertiary'
              disabled={btnDisabled}
            >
              Submit
            </CTAButton>
          </div>
        </form>
      </div>
    </div>
  )
}